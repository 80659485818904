export const SENTRY_IGNORE: string[] = [
  'Network Error',
  'Network request failed',
  'Failed to fetch',
  'NetworkError',
  'Request failed with status code 401',
  'Request failed with status code 402',
  'Request failed with status code 403',
  'Request failed with status code 404',
];

export const SUPPORT_LINKS = {
  cancel_anytime: 'https://support.plushcare.com/hc/en-us/articles/360035957554-How-do-I-cancel-my-membership',
  tos: 'https://plushcare.com/terms/',
  privacy: (isAccdCare: boolean) => (isAccdCare ? 'https://www.accoladecare.com/privacy-policy/' : 'http://plushcare.com/privacy'),
  terms_of_use: (isAccdCare: boolean) => (isAccdCare ? 'https://www.accoladecare.com/terms-of-use/' : 'http://plushcare.com/terms'),
  fee: 'https://support.plushcare.com/hc/en-us/articles/360043197853-Appointment-Change-Policy',
  faq: 'https://plushcare.com/how-it-works/#:~:text=a%20full%20refund.-,How%20it%20works%20FAQs,-What%20is%20included',
  howCanIPay: 'https://plushcare.com/how-it-works/#:~:text=How%20can%20I%20pay%20for%20PlushCare?',
  billingPolicy: 'https://plushcare.com/terms/#:~:text=Charges%20on%20Your%20Billing%20Account',
  billingPolicyNew: 'https://plushcare.com/billing-policy/',
};

export const MEMBERSHIP_TYPES = {
  ANNUAL: 'Annual',
  MONTHLY: 'Monthly',
};

export const BILLING_FREQUENCY_TEXT = {
  ANNUAL: 'Annual billing.',
  MONTHLY: 'Monthly billing.',
};
