// eslint-disable-next-line max-classes-per-file
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { SessionStorage } from 'src/js/utils/storage';
import CookieStorage from 'src/js/utils/CookieStorage';
import sleep from 'src/js/utils/functools/sleep';
// eslint-disable-next-line import/no-cycle
import Analytics from 'src/js/utils/analytics/Analytics';
import { isServer } from 'src/js/utils/server';
import { getTimezoneName } from 'src/js/utils/datetime';
import { UnknownRecord } from 'src/js/utils/types';

const { isAndroid, isIOS } = require('react-device-detect');

const plushcarePlatform = () => {
  if (isAndroid) {
    return 'Android';
  }
  if (isIOS) {
    return 'iOS';
  }
  return 'Web';
};

export const API_URL = process.env.NEXT_PUBLIC_API_URL;
const PLUSHCARE_PLATFORM = plushcarePlatform();
const APP_ZONE = process.env.NEXT_PUBLIC_APP_ZONE;

// Types
interface trackingDict {
  'initial-referrer'?: string | null;
  Page?: string | null;
  'google-analytics-id'?: string | null;
  'segment-anonymous-id'?: string | null;
  'fullstory-session-url'?: string;
}

// this exists to satisfy the TS linter in the serverReqHeadersParams interface
// without making customHeaders just 'any' type
interface optionalReqHeaders {
  user_timezone?: string;
  user_id?: string;
  timezoneOffset?: string;
  userAgent?: string;
  clientIP?: string;
}

interface serverReqHeadersParams {
  cookie?: string;
  token?: string;
  customHeaders?: optionalReqHeaders;
}

interface staticServerGetParams {
  url: string;
  cookie?: string;
  token?: string;
  params?: object;
  customHeaders?: object;
  baseURLOverride?: string;
}

interface staticServerPostParams {
  url: string;
  payload: any;
  cookie?: string;
  token?: string;
  customHeaders?: object;
}

/**
 * When we first initialize the axios instance, some tracking headers may not be available yet.
 * Here we check and set them if they're not set.
 */
const checkForMissingTrackingHeaders = (config: AxiosRequestConfig) => {
  const fullstorySessionURL = Analytics.getFullStorySessionURL();
  const requestId = crypto.randomUUID();
  const updatedConfig = {
    ...config,
    headers: {
      ...config.headers,
      'x-request-id': requestId,
    },
  };
  if (fullstorySessionURL && !updatedConfig.headers['fullstory-session-url']) {
    updatedConfig.headers['fullstory-session-url'] = fullstorySessionURL;
  }
  return updatedConfig;
};
/**
 * A wrapper class for Plushcare Web API.
 */
class PlushcareWebAPI {
  static instance: AxiosInstance | null = null;

  static directInstance: AxiosInstance | null = null;

  /**
   * Returns a singleton instance of axios with preset `csrftoken` and tracking headers.
   */
  static getAxios(headers: object) {
    if (this.instance === null) {
      this.instance = axios.create({
        baseURL: APP_ZONE || '/',
        headers: this.constructHeaders(headers),
      });
      this.instance.interceptors.request.use(checkForMissingTrackingHeaders);
    }
    return this.instance;
  }

  static getAxiosAccoladeX() {
    if (this.instance === null) {
      this.instance = axios.create({
        baseURL: '/',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
    }
    return this.instance;
  }

  static getAxiosDirect(headers: object) {
    if (this.directInstance === null) {
      this.directInstance = axios.create({
        baseURL: `${API_URL}/`,
        withCredentials: true,
        headers: this.constructHeaders(headers),
      });
      this.directInstance.interceptors.request.use(checkForMissingTrackingHeaders);
    }
    return this.directInstance;
  }

  /**
   * Returns tracking headers based on what's in Local and Session Storage.
   */

  static getTrackingHeaders() {
    const initialReferrer = SessionStorage.getItem('initial_referrer');
    const segmentAnonymousId = Analytics.getSegmentAnonymousId();
    const googleAnalyticsId = Analytics.getGoogleAnalyticsId();
    const fullstorySessionURL = Analytics.getFullStorySessionURL();
    let trackingDict: trackingDict = {};
    if (!isServer) {
      trackingDict = {
        Page: window.location.href,
        'initial-referrer': initialReferrer,
      };
    }
    if (googleAnalyticsId !== undefined) {
      trackingDict['google-analytics-id'] = googleAnalyticsId;
    }
    if (segmentAnonymousId !== undefined) {
      trackingDict['segment-anonymous-id'] = segmentAnonymousId;
    }
    if (fullstorySessionURL !== undefined) {
      trackingDict['fullstory-session-url'] = fullstorySessionURL;
    }
    return trackingDict;
  }

  static constructHeaders(headers = {}) {
    const csrftoken = CookieStorage.get('csrftoken');
    const trackingHeaders = this.getTrackingHeaders();
    const timezoneId = getTimezoneName();
    return {
      'X-Requested-With': 'XMLHttpRequest',
      ...csrftoken ? { 'X-CSRFToken': csrftoken } : {},
      'Content-Type': 'application/json',
      'Access-Control-Max-Age': 7200,
      'x-timezone-offset': CookieStorage.get('timezone_offset') || 0,
      'plushcare-platform': PLUSHCARE_PLATFORM,
      ...timezoneId ? { 'timezone-id': timezoneId } : {},
      ...trackingHeaders,
      ...headers,
    };
  }

  /**
   * Builds a FormData object for the `data` dict. Returns `null` if data is `null`.
   * @param {dict} data input data dict. Can be `null`
   */
  static formData<T>(data: T | null) {
    let formData: FormData | null = null;
    if (data) {
      formData = new FormData();
      Object.entries(data)
        .forEach(([key, value]) => formData?.set(key, value));
    }
    return formData;
  }

  /**
   * Builds a ParamData object for the `data` dict. Returns `null` if data is `null`.
   * @param {dict} data input data dict. Can be `null`
   */
  static paramData(data: object | null) {
    let paramData: FormData | null = null;
    if (data) {
      paramData = new URLSearchParams();
      Object.entries(data)
        .filter(
          ([_, value]) => (value !== undefined),
        )
        .forEach(([key, value]) => paramData?.set(key, value));
    }
    return paramData;
  }

  /**
   * Builds a url
   * @param {str} path url path
   * @param {dict} params query pararms. Can be `null`
   */
  static urlBuilder(path: string, params: object | null, absolute?: boolean) {
    const queryParams = this.paramData(params);
    const url = queryParams ? `${path}?${queryParams}` : path;
    return absolute ? `${API_URL.trim().replace(/\/$/, '')}${url}` : url;
  }

  /**
   * Calls axios' `request` method.
   * @param {dict} opts axios' request options
   * @param direct whether or not this is a direct request
   */
  static async request<T = any>(opts: any, direct: boolean, isAccoladeX = false): Promise<AxiosResponse<T>> {
    const extraHeaders = {
      'X-Accept-Async-Job': 'true',
    };
    const axs = direct
      ? this.getAxiosDirect(extraHeaders)
      : (isAccoladeX ? this.getAxiosAccoladeX() : this.getAxios(extraHeaders));
    // @ts-ignore axs possibly null
    const response = await axs.request(opts);

    if (response.status !== 290) {
      return response;
    }

    const jobId = response.headers['x-async-job-id'];
    let timeout = 1;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(timeout * 1000);
      // eslint-disable-next-line no-await-in-loop
      const resp = await this.get(`/services/task_manager/job/${jobId}`, {}, true);
      if (resp?.status !== 291) {
        return resp;
      }
      timeout = Math.min(timeout * 2, 16);
    }
  }

  /**
   * Sends GET request using axios' `request` method.
   * @param {str} url url for the request
   * @param {dict} data data to send. Can be `null`
   * @param direct direct request boolean
   */
  static async get<T = any>(url: string, data: object | null, direct = false) {
    return this.request<T>({
      url,
      method: 'get',
      params: this.paramData(data),
    }, direct);
  }

  /**
   * Sends PATCH request using axios' `request` method.
   * @param {str} url url for the request
   * @param {dict} data data to send. Can be `null`
   * @param {bool} asJson send data as JSON instead of form data (default = false)
   */
  static async patch<T = any>(url: string, data: UnknownRecord | null, asJson = false, direct = false) {
    return this.request<T>({
      url,
      method: 'patch',
      data: asJson ? data : this.formData(data),
    }, direct);
  }

  static async apiGet<T = any>(url: string, data: object | null = null) {
    return this.get<T>(url, data, true);
  }

  static async apiPost<T>(url: string, data: UnknownRecord, asJson = false) {
    return this.post<T>(url, data, asJson, true);
  }

  static async apiPatch<T>(url: string, data: UnknownRecord, asJson = false) {
    return this.patch<T>(url, data, asJson, true);
  }

  /**
   * Sends POST request using axios' `request` method.
   * @param {str} url url for the request
   * @param {dict} data data to send. Can be `null`
   * @param {bool} asJson send data as JSON instead of form data (default = false)
   */
  static async post<T = any>(url: string, data: UnknownRecord | null, asJson = false, direct = false) {
    return this.request<T>({
      url,
      method: 'post',
      data: asJson ? data : this.formData(data),
    }, direct);
  }

  static async apiGetAccoladeX<T>(url: string, data: UnknownRecord | null = null) {
    const opts = {
      url,
      method: 'get',
      params: this.paramData(data),
    };
    return this.request<T>(opts, false, true);
  }

  static async apiPostAccoladeX<T>(url: string, data: UnknownRecord | null = null, asJson = true) {
    const opts = {
      url,
      method: 'post',
      data: asJson ? data : this.formData(data),
    };
    return this.request<T>(opts, false, true);
  }

  /**
   *
   * cookie - string which will be included into headers as cookie
   * token - user auth token
   * customHeaders - object with custom headers
   */
  static buildStdServerRequestHeaders({
    cookie,
    token,
    customHeaders,
  }: serverReqHeadersParams) {
    const timezoneId = cookie ? this.retrieveCookie(cookie, 'user_timezone') : null;
    const generalHeaders = {
      'X-Requested-With': 'XMLHttpRequest',
      'sec-fetch-mode': 'cors',
      'sec-fetch-dest': 'empty',
      'X-Accept-Async-Job': 'true',
      'content-type': 'application/json',
      'Access-Control-Max-Age': '7200',
      cookie: cookie ?? '', // we cannot use undefined as it makes nextjs blow
      // token must be set for each request specifically
      // because `getAxiosDirect` constructs it from cookies, and it may be outdated in case of server side request
      ...token ? { 'X-CSRFToken': token } : {},
      'plushcare-platform': PLUSHCARE_PLATFORM,
      ...timezoneId ? { 'timezone-id': timezoneId } : {},
      ...customHeaders?.timezoneOffset ? { 'x-timezone-offset': customHeaders?.timezoneOffset } : {},
      ...customHeaders?.userAgent ? { 'user-agent': customHeaders?.userAgent } : {},
      ...customHeaders?.clientIP ? { 'pc-connecting-ip': customHeaders?.clientIP } : {},
    };
    return {
      generalHeaders,
    };
  }

  /**
   * Parses conditionally and retrieves wanted cookie
   * @param {object | string | null | undefined} cookies
   * @param {string} name
   */
  static retrieveCookie(cookies: string, name: string): string | null {
    if (!cookies) return null;
    const parts = cookies.split(`; ${name}=`);
    if (parts.length === 2) {
      // @ts-ignore
      return parts.pop()
        .split(';')
        .shift() || null;
    }
    return null;
  }

  /**
   * used by NextJS Node server. Sets custom headers, makes GET request,
   * does not access `window`, `cookies` and other stuff from BOM
   * @param url - string without BASE_URL
   * @param requestCookies - all cookies, sent from client. have to be passed to API so that
   * API successfully validates request
   * @param token - for protected APIs
   * @param params - from NextJS ssr calls
   * @param customHeaders - any additional custom headers that need to be passed to the request
   * @returns { Promise<{ data: any, error: any }> }
   */
  static async staticServerGet(args: staticServerGetParams): Promise<{ data: any, error: any }> {
    const {
      url, cookie, token, params, customHeaders, baseURLOverride,
    } = args;
    const builtHeaders = this.buildStdServerRequestHeaders({
      cookie,
      token,
      customHeaders,
    });

    const instance: AxiosInstance = axios.create({
      baseURL: baseURLOverride || `${API_URL}/`,
      withCredentials: true,
      headers: builtHeaders.generalHeaders,
      timeout: 120000,
    });

    const result = {
      data: null,
      error: null,
    };

    try {
      const requestConfig: AxiosRequestConfig = { method: 'get', url };
      if (params) {
        requestConfig.params = this.paramData(params);
      }
      const { data } = await instance.request(requestConfig);
      result.data = data;
    } catch (e: Error | any) {
      result.error = e.response ?? e;
    }

    return result;
  }

  /**
   * used by Nextjs server. Sets custom headers, populates payload data, makes POST request,
   * does not access `window`, `cookies` and other stuff from BOM
   * @param url - string without BASE_URL
   * @param payload
   * @param requestCookies - all cookies, sent from client. have to be passed to API so that
   * API successfully validates request
   * @param token - for protected APIs
   * @param customHeaders - any additional custom headers that need to be passed to the request
   * @returns { Promise<{ data: any, error: any }> }
   */
  static async staticServerPost(args: staticServerPostParams): Promise<{ data: any, error: any }> {
    const {
      url, payload, cookie, token, customHeaders,
    } = args;
    const builtHeaders = this.buildStdServerRequestHeaders({
      cookie,
      token,
      customHeaders,
    });
    // @ts-ignore
    const instance: AxiosInstance = PlushcareWebAPI.getAxiosDirect(builtHeaders.generalHeaders);
    const result = {
      data: null,
      error: null,
    };

    try {
      const { data } = await instance.request({ method: 'post', url, data: payload });
      result.data = data;
    } catch (e: Error | any) {
      result.error = e.response ?? e;
    }
    return result;
  }
}

/**
 * Parses API v2 responses into a ready-to-use object
 */
export class V2Response {
  response;

  data;

  validError;

  constructor(response: Response | any) {
    this.response = response;
    this.data = response?.data;
    this.validError = this.data.status === 'error' && this.data.error;
  }

  get success() {
    return this.data.status === 'success';
  }

  get error() {
    if (this.validError) {
      return this.data.error;
    }
    return null;
  }

  get errorCode() {
    if (this.validError) {
      return this.data.error.code_alt;
    }
    return null;
  }

  get errorCodeAPI() {
    if (this.validError) {
      return this.data.error.code;
    }
    return null;
  }

  get errorMessage() {
    if (this.validError) {
      return this.data.error.message;
    }
    return null;
  }

  get errorData() {
    if (this.validError) {
      return this.data.error.error_data;
    }
    return null;
  }

  get payload() {
    return this.data.payload;
  }
}

export default PlushcareWebAPI;
