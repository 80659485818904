import { Router } from 'next/router';

/**
 * Updates window.location with a new url.
 */
// TODO deprecate using that
export const updateLocation = (url: string) => {
  // It is not an absolute url then we need to add a base url if it exists (for example, for AccoladeX).
  let updateUrl = url;
  if (!/^(https?|ftp|file):\/\//.test(url)) {
    updateUrl = process.env.NEXT_PUBLIC_APP_ZONE ? `${process.env.NEXT_PUBLIC_APP_ZONE}${url}` : url;
  }
  (window.location as any) = updateUrl;
};

export const isRescheduleBookingFlow = (query: Router['query']) => !!rescheduleAppointmentId(query);

export const rescheduleAppointmentId = (query: Router['query']) => query.appointmentId as string | undefined;

// PLUS-7025 & PLUS-9287: Security bug, do not allow redirects to external URLs
export const getNextRoute = (query: Router['query']) => {
  if (query.next) {
    return sanitizeNextRoute(query.next as string);
  }
  return null;
};

export const sanitizeNextRoute = (nextRoute: string) => {
  // eslint-disable-next-line max-len
  const regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm;
  const DOMAIN_WHITELIST = /files(.+)(accolade|plush)care.com(.+)/g; // regex to match Librarian links
  const isURL = nextRoute.match(regex) && !nextRoute.match(DOMAIN_WHITELIST);
  return isURL ? null : nextRoute;
};
