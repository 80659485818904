import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/nextjs';
import { initBraze } from 'lib/api/braze';
import CookieStorage from 'src/js/utils/CookieStorage';
import { isBrowser, isServer } from 'src/js/utils/server';
import { appUrl, appUrlToName } from 'src/js/utils/urls';
import { APP_VARIANT, AppVariants } from 'utils/hooks/useAppVariant';
import { toTitleCase } from 'utils';
import { NO_SERVER_RESPONSE } from '../apiV2';

const ERROR_TAG = {
  booking: {
    selectAppt: 'select-appt',
    payment: {
      pc: 'payment-pc',
      therapy: 'payment-therapy',
    },
    auth: 'auth',
    insurance: 'insurance',
  },
  analytics: 'analytics',
};

export const ERROR_TRIGGER = {
  ON_LOAD: 'onLoad',
  ON_PAGE: 'onPage',
  ON_CLICK: 'onClick',
};

export const PRICE_FETCHING_ERROR = {
  message: 'Error fetching price. Please contact support.',
  cause: 'default_error',
};

let pageProperties = {};
let braze = null;
class Analytics {
  static DATADOG_ACTIONS = [
    'Web - Method - Click - Use my insurance Btn',
    'Web - Method - Click - Paying for myself Btn',
    'Web - Insurance Search - Click - Continue Button',
    'Web - Insurance Search - Click - Skip insurance btn',
    'Web - State Selection - Click - Continue Button',
    'Appointment selected',
    'New Patient',
    'Web - Payment page - Click - Book appointment btn',
    'Payment page loaded',
    'Pending coverage - modal - view',
  ];

  static setPageProperties(properties) {
    pageProperties = { ...pageProperties, ...properties };
  }

  static getGoogleAnalyticsId() {
    let clientId;
    const { ga } = isServer ? {} : window;
    if (ga && Object.prototype.hasOwnProperty.call(ga, 'getAll')) {
      const [tracker] = ga.getAll();
      if (tracker) clientId = tracker?.get('clientId');
    } else {
      const clientIdCookie = CookieStorage.get('_ga');
      if (clientIdCookie) {
        clientId = clientIdCookie.split('.').slice(-2).join('.');
      }
    }
    return clientId || '';
  }

  static getFullStorySessionURL() {
    let sessionURL;
    const { FS } = isServer ? {} : window;
    if (FS && Object.prototype.hasOwnProperty.call(FS, 'getCurrentSessionURL')) {
      sessionURL = FS.getCurrentSessionURL();
    }
    return sessionURL;
  }

  static getSegmentAnonymousId() {
    const { analytics } = isServer ? {} : window;
    if (analytics?.user) {
      return analytics.user().anonymousId();
    }
    return CookieStorage.get('ajs_anonymous_id');
  }

  static getUserId() {
    const { analytics } = isServer ? {} : window;
    if (analytics?.user) {
      return analytics.user().id();
    }
    return CookieStorage.get('ajs_user_id');
  }

  static defaultProperties() {
    try {
      return {
        clientId: this.getGoogleAnalyticsId(),
        application_viewed: (APP_VARIANT === AppVariants.AccoladeCare) ? 'accoladecare-web' : 'web',
      };
    } catch (e) {
      this.analyticsException(e);
      return {};
    }
  }

  /**
   * This function add data to the GTM dataLayer
   *
   * Why do we need this?
   * Braze needs the dataLayer to be updated before the event looking for them is fired in GTM.
   * no events is sent from Web to Braze directly.
   * - GTM fires the event
   * - The event gets to a Web Page, reads the dataLayer for values
   *   of attached to the GTM event as Event Properties.
   * - the GTM the  the embedded Braze SDK and forward the event along with the properties
   *
   * A race condition between when the dataLayer is updated vs when the braze SDK
   * is actually called may occur, so we need to make sure the dataLayer is updated
   * with the data GTM and Braze would expect to have
   *
   * @param {dictionary} data a keypair value to be added to the GTM dataLayer
   */
  static addToDataLayer(data) {
    this.setPageProperties(data);
    // eslint-disable-next-line no-undef
    globalThis?.analytics?.dataLayer?.push(data);
  }

  static updateTraits(attributes) {
    try {
      const { analytics } = window;
      // In case the analytics is blocked by the browser do nothing.
      if (!analytics.user) {
        return;
      }
      const user_id = analytics.user().id();
      if (user_id) {
        analytics.identify(user_id, attributes);
      }
    } catch (e) {
      this.analyticsException(e);
    }
  }

  /**
    * Calls identify method for analytics sources
    * @param {object} user logged in user dictionary
    * @param {function} callback function to invoke on successful/unsuccessful identify
  * */
  static async identify(user, callback = () => { }) {
    if (isServer) return; // return early if window is undefined
    try {
      const { analytics, FS } = window;
      const customProperties = this.defaultProperties();
      const {
        user_id, is_admin, source,
      } = user;
      const {
        group, repeat_patient, date_joined,
      } = user.userprofile;
      const { external_id } = source || {};

      // custom properties
      customProperties.tpa_group_id = group?.group_id || 'N/A';
      customProperties.repeat_patient = repeat_patient;
      customProperties.date_joined = date_joined;
      customProperties.is_admin = is_admin;
      customProperties.external_id = external_id || 'N/A';

      analytics.identify(user_id, customProperties, callback);
      // Call Sentry Identify
      Sentry.setUser({ id: user_id });

      if (FS && user_id) {
        FS.identify(user_id);
        FS.setUserVars({
          segment_anonymous_id: this.getSegmentAnonymousId(),
        });
      }

      datadogRum.setUser({ id: user_id });
      braze = await initBraze();
      braze.changeUser(user_id);
    } catch (e) {
      this.analyticsException(e);
      callback();
    }
  }

  static makeDataLayerProps(customProperties) {
    const dataLayerProps = { ...customProperties };
    const anonId = this.getSegmentAnonymousId();
    const userId = this.getUserId();
    if (anonId) dataLayerProps.segmentAnonymousId = anonId;
    if (userId) dataLayerProps.userId = userId;
    return dataLayerProps;
  }

  /**
    * Calls page view method for analytics sources
    * @param {function} callback function to invoke on successful/unsuccessful page view
  * */
  static page(acquisitionCookies = {}, callback = () => { }) {
    // disable for forget password page
    if (isBrowser() && window.location && !window.location.pathname.includes(appUrl.password.resetConfirm)) {
      try {
        const { analytics } = window;
        // default + page
        let customProperties = {
          ...this.defaultProperties(),
          ...pageProperties,
          pc_campaign: acquisitionCookies,
        };
        let personId = null;
        // In case the analytics is blocked by the browser do nothing.
        if (analytics?.user) {
          const userTraits = analytics.user().traits();
          if (userTraits?.person_id) {
            personId = userTraits.person_id;
          }
          if (userTraits?.employee) {
            customProperties = {
              ...customProperties,
              enterprise: userTraits.employee,
            };
          }
        }
        const pageName = appUrlToName(window.location.pathname);
        if (pageName) customProperties.name = pageName;
        const options = {
          context: {
            personId,
          },
        };
        if (analytics?.page) {
          analytics.page(customProperties, options);
        }
      } catch (error) {
        this.analyticsException(error);
        callback();
      }
    }
  }

  /**
    * Calls track custom event method for analytics sources
    * @param {string} eventName name of tracking event
    * @param {object} properties key/value pair of attributes to send
    * @param {function} callback function to invoke on successful/unsuccessful track
  * */
  static async track(eventName, properties = {}, callback = () => { }, forwardToBraze = false) {
    if (this.DATADOG_ACTIONS.includes(eventName)) {
      datadogRum.addAction(eventName, {
        segment_anonymous_id: this.getSegmentAnonymousId(),
        application_viewed: (APP_VARIANT === AppVariants.AccoladeCare) ? 'accoladecare-web' : 'web',
      });
    }
    // disable for forget password page
    if (isBrowser() && window.location && !window.location.pathname.includes(appUrl.password.resetConfirm)) {
      try {
        if (typeof eventName !== 'string') {
          throw Error('Assign an eventName before calling track!');
        }
        const { analytics } = window;
        const customProperties = { ...properties, ...this.defaultProperties() };
        await analytics.track(eventName, customProperties, callback);

        if (forwardToBraze) {
          if (!braze) {
            const userId = this.getUserId();
            braze = await initBraze(userId);
          }

          braze?.logCustomEvent(toTitleCase(eventName), customProperties);
        }
      } catch (error) {
        this.analyticsException(error);
        callback();
      }
    }
  }

  static genericExceptionDispatch(exception, context = undefined) {
    datadogRum.addError(exception, context, 'source');
    Sentry.captureException(exception);
  }

  /**
   * Send exception data from Select Appointment interaction
   * @param {Error} exception to forward to Sentry, Datadog
   */
  static selectApptException(exception) {
    // Set a tag so we can filter on it in Sentry - do this only for local scope, not global
    Sentry.withScope((scope) => {
      scope.setTag('component', ERROR_TAG.booking.selectAppt);
      this.genericExceptionDispatch(exception);
    });
  }

  static paymentPCException(exception, trigger, origin = 'not defined') {
    // Set a tag so we can filter on it in Sentry - do this only for local scope, not global
    Sentry.withScope((scope) => {
      scope.setTag('component', ERROR_TAG.booking.payment.pc);
      this.genericExceptionDispatch(exception, {
        component: ERROR_TAG.booking.payment.pc,
        trigger,
      });
    });

    if (!exception?.response?.status) {
      const errorMessage = origin === 'get_price' ? PRICE_FETCHING_ERROR.message : NO_SERVER_RESPONSE.message;
      this.track('Payment Page Error', {
        error: errorMessage,
        origin,
      });
    }
  }

  static paymentTherapyException(exception, trigger) {
    // Set a tag so we can filter on it in Sentry - do this only for local scope, not global
    Sentry.withScope((scope) => {
      scope.setTag('component', ERROR_TAG.booking.payment.therapy);
      this.genericExceptionDispatch(exception, {
        component: ERROR_TAG.booking.payment.therapy,
        trigger,
      });
    });
  }

  static insuranceException(exception) {
    // Set a tag so we can filter on it in Sentry - do this only for local scope, not global
    Sentry.withScope((scope) => {
      scope.setTag('component', ERROR_TAG.booking.insurance);
      this.genericExceptionDispatch(exception);
    });
  }

  static authException(exception) {
    // Set a tag so we can filter on it in Sentry - do this only for local scope, not global
    Sentry.withScope((scope) => {
      scope.setTag('component', ERROR_TAG.booking.auth);
      this.genericExceptionDispatch(exception);
    });
  }

  static analyticsException(exception) {
    // Set a tag so we can filter on it in Sentry - do this only for local scope, not global
    Sentry.withScope((scope) => {
      scope.setTag('component', ERROR_TAG.analytics);
      this.genericExceptionDispatch(exception);
    });
  }

  /**
   * Generic exception handler, should avoid using this in favor of explicit method
   * @param {Error} exception to forward to Sentry, Datadog
   */
  static otherException(exception) {
    this.genericExceptionDispatch(exception);
  }

  /**
   * Generic warning handler, should avoid using this in favor of explicit method
   * @param {Error} exception to forward to Sentry, Datadog
   */
  static otherWarning(exception) {
    Sentry.withScope((scope) => {
      scope.setLevel('warning');
      this.genericExceptionDispatch(exception);
    });
  }

  /**
    * Used to determine if identify should be called again
    * @param {string} userID unique UUID associated with a user
  * */
  static newUserCheck(userID) {
    try {
      const { analytics } = window;
      return !analytics.user().id() || analytics.user().id() !== userID;
    } catch (error) {
      this.analyticsException(error);
      return false;
    }
  }
}
export default Analytics;
