import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { ThemeProvider as StyledThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import Page from 'components/Page';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'public/css/bulma-custom.scss';
import 'public/css/styles.scss';
import { wrapper } from 'src/js/store/GlobalStore';
import { useCareerPromoInConsole } from 'utils';
import { OneTrustProvider, ScriptsWrapper, DOCUMENT_LOCATIONS } from '@accolade/care-analytics/react';
import { Spinner, theme as adsTheme, ThemeProvider } from '@accolade/design-system-react';
import { AppVariants, useAppVariant } from 'utils/hooks/useAppVariant';
import { themes } from 'src/js/nextgen/themes';
import { apiUrl, PlushcareWebAPI } from 'src/js/utils';
import { BrandProvider, DEFAULT_PLUSHCARE_BRAND } from 'src/js/nextgen/providers/BrandProvider';
import { isLoggedIn } from 'src/js/store/selectors';
import { AuthHealth } from 'src/js/components/BaseComponents/AuthHealth';
import ABTestProvider from 'src/js/abTesting/components/ABTestProvider';
import AnalyticsProvider from 'src/js/utils/analytics/AnalyticsProvider';
import TermsOfServiceProvider from 'src/js/nextgen/providers/TermsOfServiceProvider';
import { assignAnonymousId, fetchDatafileFromNodeAPI } from 'src/js/abTesting/helpers';
import { isBrowser } from 'src/js/utils/server';
import CriteoInit from 'src/js/scripts/criteo/CriteoInit';
import LoadingIndicator from 'src/js/components/BaseComponents/LoadingIndicator';
import { colors } from 'src/js/nextgen/plushcare-components';

export const BrandLoader = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
`;

function MyApp({
  Component, pageProps, optimizelyDatafile, anonymousId,
}) {
  const { variant } = useAppVariant();

  const [brand, setBrand] = useState(null);
  const colorScheme = brand ? brand.color_scheme : variant;

  const loggedIn = useSelector(isLoggedIn);
  const router = useRouter();

  useEffect(() => {
    if (variant !== AppVariants.PlushCare) {
      const params = (router.isReady && router.query.group_id) ? { group_id: router.query.group_id } : '';
      PlushcareWebAPI.apiGet(apiUrl.partnersBrand, params).then(({ data: { payload } }) => setBrand(payload));
    }
  }, [loggedIn, variant, router.isReady]);

  // NOTE: By setting `dangerouslySetTheme` on the ADS `ThemeProvider`
  // we lose support for light/dark and compact/standard modes out of the box.
  // Currently we don't use those, but if we ever wanted to in the future,
  // we would have to set those values ourselves.
  // Work with the ADS team if this ever becomes a requirement.
  const customAdsTheme = colorScheme === AppVariants.Bsc
    ? {
      ...adsTheme,
      colors: {
        ...adsTheme.colors,
        primary: {
          default: '#0085CA',
          hover: '#50B0E2',
          selected: '#0085CA',
          focus: '#0085CA',
          disabled: '#A0C8DD',
          background: '#B5E6FF',
        },
      },
    } : { ...adsTheme };

  // hack to make payment page buttons the appropriate colour on PlushCare + AccoladeCare when selected + disabled
  // at the same time
  if (colorScheme === AppVariants.PlushCare) {
    customAdsTheme.colors.primary.focus = colors.sunshine;
  }

  const emotionTheme = themes[colorScheme];

  useCareerPromoInConsole();
  // below was caching user data in next server
  // the issue was that we have no proper key to do that per user
  // as a result, on some pages users could see other users cached data from the next server
  // useUserData(pageProps.user);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (variant === AppVariants.AccoladeCare && !brand) {
    return (
      <OneTrustProvider>
        <ThemeProvider dangerouslyDisableGlobalStyle dangerouslySetTheme={customAdsTheme}>
          <BrandLoader>
            <Spinner size="l" />
          </BrandLoader>
        </ThemeProvider>
      </OneTrustProvider>
    );
  }
  const fallbackPlushCareBrand = (variant === AppVariants.PlushCare && !brand) ? DEFAULT_PLUSHCARE_BRAND : {};

  if (!isClient) {
    return <LoadingIndicator displayActivityIndicator loadingText="" />;
  }
  /* eslint-enable react-hooks/exhaustive-deps, react-hooks/rules-of-hooks */
  return (
    <ABTestProvider datafile={optimizelyDatafile} anonymousId={anonymousId}>
      <OneTrustProvider>
        <BrandProvider brand={brand || fallbackPlushCareBrand}>
          <ThemeProvider dangerouslyDisableGlobalStyle dangerouslySetTheme={customAdsTheme}>
            <StyledThemeProvider theme={emotionTheme}>
              <AnalyticsProvider>
                <TermsOfServiceProvider>
                  <Page>
                    <AuthHealth />
                    <Component {...pageProps} />
                  </Page>
                </TermsOfServiceProvider>
              </AnalyticsProvider>
            </StyledThemeProvider>
          </ThemeProvider>
        </BrandProvider>
        <ScriptsWrapper location={DOCUMENT_LOCATIONS.HEAD}>
          <CriteoInit />
        </ScriptsWrapper>
      </OneTrustProvider>
    </ABTestProvider>
  );
}

/**
  * @param {import('next/dist/shared/lib/utils').AppContextType} context
  * @returns {Promise<{optimizelyDatafile: string | null, anonymousId: string | null}>}
  * */
MyApp.getInitialProps = async ({ ctx }) => {
  let optimizelyDatafile;
  let anonymousId;

  if (isBrowser()) {
    return {
      optimizelyDatafile,
      anonymousId,
    };
  }
  // We don't want to trigger the datafile fetch or anonymousId assignment for static assets or API routes, as
  // sometimes Nextjs has a stroke and will trigger getInitialProps for requests to static assets, eg. the favicon.
  // This makes the assignAnonymousId function try to assign a cookie header to a response for a static asset,
  // which makes Nginx throw an error because Nginx allocates a buffer of a specific size for the headers, and for
  // static assets the cookie header being assigned causes a buffer overflow
  // software is cool
  //
  // this ends up being the same as the config.matcher check in middleware.ts
  const shouldSkip = ctx.req.url?.match(/(api|_next\/static|_next\/image|_next\/data|favicon)/);
  if (shouldSkip) {
    return {
      optimizelyDatafile,
      anonymousId,
    };
  }
  optimizelyDatafile = await fetchDatafileFromNodeAPI();
  anonymousId = assignAnonymousId(ctx);
  return { optimizelyDatafile, anonymousId };
};

export default wrapper.withRedux(MyApp);
