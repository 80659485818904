import { OptimizelyDecision, UserAttributes } from '@optimizely/optimizely-sdk';
import Analytics from 'src/js/utils/analytics/Analytics';
import { FLAGS } from 'src/js/abTesting/flags';
import DevLogger from 'src/js/logging/DevLogger';

type OptimizelyDecisionNotification = OptimizelyDecision & {
  type: string;
  attributes: UserAttributes;
  decisionInfo: Omit<OptimizelyDecision, 'userContext'> & { decisionEventDispatched: boolean };
  userId: string;
}
export const onDecision = (decision: OptimizelyDecisionNotification) => {
  // this is just for verifying that the conditional flag decision event behaves as expected for QA
  if (decision.decisionInfo.flagKey === FLAGS.D2C_CHILD_BOOKING_FLOW) {
    DevLogger.log('D2C child booking flow decision: ', decision);
  }

  const { userId, attributes, decisionInfo: { decisionEventDispatched, variationKey, flagKey } } = decision;
  if (decisionEventDispatched) {
    const anonId = Analytics.getSegmentAnonymousId();
    if (anonId !== userId) {
      Analytics.analyticsException('Web - Optimizely User ID Mismatch');
    }
    Analytics.track('Web - Optimizely Decision Notification', { variantID: variationKey, experimentID: flagKey, attributes });
  }
};
