import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { breakpoints, colors } from '../helpers/plushcare-style-defaults';

type Props = PropsWithChildren<{
  [k: string]: any
  to: string
  title?: string
  className?: string
  passHref?: boolean
}>

// uses Nextjs Link component
export function LinkTile({
  to, title, className, children, onClick, passHref = true,
}: Props) {
  return (
    <Link legacyBehavior href={to} passHref={passHref}>
      <Wrapper title={title} className={className} onClick={onClick}>
        {children}
      </Wrapper>
    </Link>
  );
}

const Wrapper = styled.a`
  display: flex;
  align-items: flex-start;
  padding: 8px;
  border: 1px solid ${colors.lightBackgroundGray};
  box-shadow: 0 1px 2px 0 ${colors.borderGray};
  border-radius: 8px;
  margin-bottom: 10px;

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
      border: 1px solid ${props => props.theme.colors.foreground};
      box-shadow: 0 5px 9px -2px ${colors.cloud};
    }
  }
}
`;

export const LinkTileHeader = styled.p`
  color: ${colors.darkGray};
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-top: 0;
  margin-bottom: 7px;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const LinkTileDescription = styled.p`
  color: ${colors.darkGray};
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 0;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const LinkTileIconContainer = styled.div`
  max-width: 50px;
  width: 50px;

  svg, img {
    display: block;
    width: 100%;
   
  }
`;
